export default {
  namespaced: true,
  state: {
    assetsPath: '/',
  },
  getters: {
    getAssetsPath: state => state.assetsPath,
  },
  mutations: {
    setAssetsPath(state, path) {
      state.assetsPath = path
    },
  },
  actions: {
  },
};
