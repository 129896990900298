// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

const baseURL = 'https://ag-api.2youat.com'
const appName = 'AGENT MANAGEMENT SYSTEM'
// const appLogoImage = require('@/assets/images/logo/logo-original.png')
const appLogoImage = '/logo.png'

// APP CONFIG
export const $themeConfig = {
  // baseURL: 'https://ag-api.ismgaming.com',

  // dev
  baseURL,

  app: {
    appName, // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    // appLogoImage: require('@/assets/images/logo/logofull.png'), // Will update logo in navigation menu (Branding)
    appLogoImage, // Will update logo in navigation menu (Branding)
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'sticky', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'hidden', // static, sticky, hidden
    },
    customizer: false,
    enableScrollToTop: true,
  },
}

export const UpdateThemeConfig = ({
  ConfigAppname,
  ConfigApplogoimage,
}) => {
  Object.assign($themeConfig, {
    app: {
      appName: ConfigAppname || appName,
      appLogoImage: ConfigApplogoimage || appLogoImage,
    },
  })
}
