import Vue from 'vue'
import numeral from 'numeral'
// load a locale
numeral.register('locale', 'th', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'm',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  ordinal(number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '฿',
  },
})

// switch between locales
numeral.locale('th')

Vue.filter('formatNumberMoney', value => numeral(value).format('0,0.00'))
