import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import LoadingComponent from '@/components/LoadingComponent.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import vSelect from 'vue-select'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.component("v-select", vSelect);

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(LoadingComponent.name, LoadingComponent)
