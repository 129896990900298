// eslint-disable-next-line import/no-cycle
import { getCompactMode } from '@/utils/viewtable';

const compactModeDataInitial = getCompactMode()

const initialState = {
  compactMode: compactModeDataInitial?.compactMode ?? false,
}

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  getters: {
    compactMode: state => state.compactMode,
  },
  mutations: {
    compactMode(state, val) {
      state.compactMode = val
      localStorage.setItem("vtcompactMode", JSON.stringify({ compactMode: val }));
    },
  },
  actions: {},
};
