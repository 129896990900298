export default {
  namespaced: true,
  state: {
    breadcrumbs: [],
  },
  getters: {
    getBreadcrumbs: state => state.breadcrumbs,
  },
  mutations: {
    UPDATE_BREADCRUMBS(state, payload) {
      state.breadcrumbs = Array.isArray(payload) ? Array.from(payload) : []
    },
    ADD_BREADCRUMBS(state, payload) {
      const index = state.breadcrumbs.findIndex(list => list.text === payload.text)
      if (index !== -1) {
        state.breadcrumbs.splice(index + 1)
      } else {
        state.breadcrumbs = [...state.breadcrumbs, payload]
      }
    },
    REPLACE_ROOT_QUERY_BREADCRUMBS(state, payload) {
      const index = state.breadcrumbs.findIndex(list => list.text === '<username>')
      if (index !== -1) {
        state.breadcrumbs = state.breadcrumbs.map((list, i) => {
          const newList = Object.assign({}, list, {
            query: list?.to?.query ?? {},
          })
          if (i === index) {
            newList.to.query = payload
          }
          return list
        })
      }
    },
    REMOVE_BREADCRUMBS_CUSTOM(state, payload) {
      state.breadcrumbs = state.breadcrumbs.filter(list => !('custom' in list))
    },
  },
  actions: {},
}
