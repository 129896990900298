export default [
  {
    path: '/',
    name: 'profile',
    component: () => import('@/views/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
      action: 'read',
      resource: 'loggedIn',
    },
  },
  {
    path: '/change-password',
    name: 'changepassword',
    component: () => import('@/views/ChangePassword.vue'),
    meta: {
      pageTitle: 'Change Password',
      breadcrumb: [
        {
          text: 'Change Password',
          active: true,
        },
      ],
      action: 'read',
      resource: 'loggedIn',
    },
  },
  {
    path: '/change-password',
    name: 'requireChangePassword',
    component: () => import('@/views/ChangePassword.vue'),
    meta: {
      pageTitle: 'Change Password',
      breadcrumb: [
        {
          text: 'Change Password',
          active: true,
        },
      ],
      action: 'read',
      resource: 'loggedIn',
      layout: 'full',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Dashboard',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'public',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    // ! Update import path
    component: () => import('@/views/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      // action: 'read',
      // resource: 'loggedIn',
      resource: 'public',

    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
