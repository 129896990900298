export default [
  // member
  {
    path: '/accounts/:downlineId?',
    name: 'accounts',
    component: () => import('@/views/accounts/Accounts.vue'),
    meta: {
      pageTitle: 'Accounts Management',
      action: 'read',
      resource: 'Accounts',
      breadcrumb: [
        {
          text: 'Accounts Management Agent',
          to: { name: "accounts" },
        },
      ],
    },
  },
  {
    path: '/accounts/edit/:id',
    name: 'accounts-edit',
    component: () => import('@/views/accounts/AccountsCreateEdit.vue'),
    meta: {
      pageTitle: 'Accounts Management',
      action: 'read',
      resource: 'Accounts',
      navActiveLink: 'accounts',
      breadcrumb: [
        {
          text: 'Accounts Edit',
          to: { name: "accounts-edit" },
        },
      ],
    },
  },
  {
    path: '/accounts/create/new',
    name: 'accounts-create',
    // component: () => import('@/views/accounts/AccountsCreate.vue'),
    component: () => import('@/views/accounts/AccountsCreateEdit.vue'),
    meta: {
      pageTitle: 'Accounts Create ',
      action: 'create',
      resource: 'Accounts',
      navActiveLink: 'accounts',
      breadcrumb: [
        {
          text: 'Accounts Management Agent',
          to: { name: "accounts" },
        },
        {
          text: 'Accounts Create',
          active: true,
        },
      ],
    },
  },

  // sub accounts
  {
    path: '/sub-accounts/:downlineId?',
    name: 'sub-accounts',
    component: () => import('@/views/sub-accounts/SubAccounts.vue'),
    meta: {
      pageTitle: 'Sub Accounts Management',
      action: 'read',
      resource: 'SubAccounts',
      breadcrumb: [
        {
          text: 'Sub Accounts Management Agent',
          to: { name: "sub-accounts" },
        },
      ],
    },
  },
  {
    path: '/sub-accounts/edit/:id',
    name: 'sub-accounts-edit',
    component: () => import('@/views/sub-accounts/SubAccountsCreateEdit.vue'),
    meta: {
      pageTitle: 'Sub Accounts Management',
      action: 'read',
      resource: 'SubAccounts',
      navActiveLink: 'sub-accounts',
      breadcrumb: [
        {
          text: 'Sub Accounts Edit',
          to: { name: "sub-accounts-edit" },
        },
      ],
    },
  },
  {
    path: '/sub-accounts/create/new',
    name: 'sub-accounts-create',
    component: () => import('@/views/sub-accounts/SubAccountsCreateEdit.vue'),
    meta: {
      pageTitle: 'Sub Accounts Create',
      action: 'create',
      resource: 'SubAccounts',
      navActiveLink: 'sub-accounts',
      breadcrumb: [
        {
          text: 'Sub Accounts Management Agent',
          to: { name: "sub-accounts" },
        },
        {
          text: 'Sub Accounts Create',
          active: true,
        },
      ],
    },
  },
  // members

  // {
  //   path: '/members',
  //   name: 'members',
  //   component: () => import('@/views/accounts/Members.vue'),
  //   meta: {
  //     pageTitle: 'Members management',
  //     action: 'read',
  //     resource: 'Members',
  //   },
  // },

  {
    path: '/members/:memberId?',
    name: 'members',
    component: () => import('@/views/members/Members.vue'),
    meta: {
      pageTitle: 'Members Management',
      action: 'read',
      resource: 'Members',
      breadcrumb: [
        {
          text: 'Members Management',
          to: { name: "members" },
        },
      ],
    },
  },
  {
    path: '/members/edit/:id',
    name: 'members-edit',
    component: () => import('@/views/members/MembersCreateEdit.vue'),
    meta: {
      pageTitle: 'Members Management',
      action: 'read',
      resource: 'Members',
      navActiveLink: 'members',
      breadcrumb: [
        {
          text: 'Members Management',
          to: { name: "members" },
        },
        {
          text: 'Members Edit',
          to: { name: "members-edit" },
        },
      ],
    },
  },
  {
    path: '/members/create/new',
    name: 'members-create',
    component: () => import('@/views/members/MembersCreateEdit.vue'),
    meta: {
      pageTitle: 'Members Create ',
      action: 'create',
      resource: 'Members',
      navActiveLink: 'members',
      breadcrumb: [
        {
          text: 'Members Management',
          to: { name: "members" },
        },
        {
          text: 'Members Create',
          active: true,
        },
      ],
    },
  },

  {
    path: '/member/clone-setting',
    name: 'member-clone-setting',
    component: () => import('@/views/members/MemberCloneSetting.vue'),
    meta: {
      pageTitle: 'Member Clone Setting',
      action: 'read',
      resource: 'Members',
      navActiveLink: 'member-clone-setting',
      breadcrumb: [
        {
          text: 'Members Management',
          to: { name: "members" },
        },
        {
          text: 'Member Clone Setting',
          active: true,
        },
      ],
    },
  },
]
