export default [
  // logs
  {
    path: '/logs/agent-credit',
    name: 'log-agent-transactions',
    component: () => import('@/views/logs/LogAgentTransactions.vue'),
    meta: {
      pageTitle: 'Log Agent Transactions',
      action: 'read',
      resource: 'LogAgentTransactions',
    },
  },
  {
    path: '/logs/member-credit',
    name: 'log-member-transactions',
    component: () => import('@/views/logs/LogMemberTransactions.vue'),
    meta: {
      pageTitle: 'Log Member Transactions',
      action: 'read',
      resource: 'LogMemberTransactions',
    },
  },

]
