import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import accountCreateSetting from './account-create-setting'
import appBreadCrumb from './app-breadcrumb'
import auth from './auth'
import setting from './setting'
import viewtable from './viewtable'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    accountCreateSetting,
    appBreadCrumb,
    auth,
    setting,
    viewtable,
  },
  strict: process.env.DEV,
})
