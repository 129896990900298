export default [
  // logs
  {
    path: '/auto-partner/setting',
    name: 'auto-partner-setting',
    component: () => import('@/views/auto-partner/AutoPartnerSetting.vue'),
    meta: {
      pageTitle: 'Auto Partner Setting',
      action: 'read',
      resource: 'AutoPartner',
    },
  },
]
