<template>
  <div class="d-flex justify-content-center p-2">
    <b-spinner />
  </div>
</template>

<script>
export default {
  name: "Loading",
}
</script>

<style>

</style>
