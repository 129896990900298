import get from 'lodash/get'
import set from 'lodash/set'
import clone from 'lodash/clone'

export default {
  namespaced: true,
  state: {
    settingType: "NEW",
    initCommissionFrontend: {},
    initSetting: {},
    initSettingCommission: {},
    initSettingLimit: {},
    setting: {
      casino: {
        sa: {
          max: 0,
          min: 0,
          share: 0,
          force: 0,
          upline: 0,
          commission: 0,
          isEnable: false,
        },
      },
      game: {
        slot: {
          max: 0,
          min: 0,
          share: 0,
          force: 0,
          upline: 0,
          commission: 0,
          isEnable: false,
        },
      },
    },
    settingCommission: {},
    settingLimit: {},

    // upline
    uplineInitSetting: {},
    uplineInitSettingCommission: {},
    uplineInitSettingLimit: {},
    uplineSetting: {
      casino: {},
      game: {},
    },
    uplineSettingCommission: {},
    uplineSettingLimit: {},

    upline_status: {
      active: false,
      setting: {
        casino: {
          sa: {
            isEnable: false,
          },
        },
        game: {
          slot: {
            isEnable: false,
          },
        },
      },
    },
    // end upline

    prefix: '',
    isMember: false,
  },
  getters: {
    getInitCommissionFrontend: state => state.initCommissionFrontend,
    getInitSetting: state => state.initSetting,
    getInitSettingCommission: state => state.initSettingCommission,
    getInitSettingLimit: state => state.initSettingLimit,
    getSetting: state => state.setting,
    getSettingCommission: state => state.settingCommission,
    getSettingLimit: state => state.settingLimit,

    getUplineInitSetting: state => state.uplineInitSetting,
    getUplineInitSettingCommission: state => state.uplineInitSettingCommission,
    getUplineInitSettingLimit: state => state.uplineInitSettingLimit,
    getUplineSetting: state => state.uplineSetting,
    getUplineSettingCommission: state => state.uplineSettingCommission,
    getUplineSettingLimit: state => state.uplineSettingLimit,

    getUplineStatus: state => state.upline_status,
    getSettingType: state => state.settingType,
    getIsMember: state => state.isMember,
  },
  mutations: {
    INIT_COMMISSION_FRONTEND(state, payload) {
      state.initCommissionFrontend = { ...state.initCommissionFrontend, ...payload }
    },

    INIT_SETTING(state, payload) {
      state.initSetting = { ...state.initSetting, ...payload }
    },
    INIT_SETTING_COMMISSION(state, payload) {
      state.initSettingCommission = { ...state.initSettingCommission, ...payload }
    },
    INIT_SETTING_LIMIT(state, payload) {
      state.initSettingLimit = { ...state.initSettingLimit, ...payload }
    },
    UPDATE_SETTING(state, payload) {
      state.setting = { ...state.setting, ...payload }
    },
    UPDATE_SETTING_COMMISSION(state, payload) {
      state.settingCommission = { ...state.settingCommission, ...payload }
    },
    UPDATE_SETTING_LIMIT(state, payload) {
      state.settingLimit = { ...state.settingLimit, ...payload }
    },

    // upline
    UPLINE_INIT_SETTING(state, payload) {
      state.uplineInitSetting = { ...state.uplineInitSetting, ...payload }
    },
    UPLINE_INIT_SETTING_COMMISSION(state, payload) {
      state.uplineInitSettingCommission = { ...state.uplineInitSettingCommission, ...payload }
    },
    UPLINE_INIT_SETTING_LIMIT(state, payload) {
      state.uplineInitSettingLimit = { ...state.uplineInitSettingLimit, ...payload }
    },
    UPDATE_UPLINE_SETTING(state, payload) {
      state.uplineSetting = { ...state.uplineSetting, ...payload }
    },

    // upline

    UPDATE_SETTING_STATUS(state, payload) {
      state.upline_status = { ...state.upline_status, ...payload }
    },
    UPDATE_SETTING_TYPE(state, payload) {
      state.settingType = payload
    },
    UPDATE_SETTING_AGENT_PREFIX(state, payload) {
      state.prefix = payload
    },
    UPDATE_SETTING_IS_MEMBER(state, payload) {
      state.isMember = payload
    },
    UPDATE_SETTING_BY_KEY(state, payload) {
      // const ref = `setting.${payload.key}`
      // console.log('UPDATE_SETTING_BY_KEY', {
      //   setting: {
      //     ...state.setting,
      //     'game.slot': {
      //       aa: 'aa',
      //     },
      //   },
      // })

      const originalState = clone(state.setting)
      state.setting = set(originalState, payload.key, {
        ...get(originalState, payload.key, {}),
        ...payload.data,
      })

      // state[ref] = Object.assign(state[ref], payload.data)
    },
    UPDATE_SETTING_COMMISSION_BY_KEY(state, payload) {
      const originalState = clone(state.settingCommission)
      state.settingCommission = set(originalState, payload.key, {
        ...get(originalState, payload.key, {}),
        ...payload.data,
      })
    },
    UPDATE_SETTING_LIMIT_BY_KEY(state, payload) {
      const originalState = clone(state.settingLimit)
      state.settingLimit = set(originalState, payload.key, {
        ...get(originalState, payload.key, {}),
        ...payload.data,
      })
    },
  },
  actions: {},
}
