// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import axios from '@axios'
import ability from '../libs/acl/ability'

const userDataInitial = getUserData()

const initialState = userDataInitial
  ? { loggedIn: true, user: userDataInitial }
  : { loggedIn: false, user: null }

export default {
  namespaced: true,
  state: {
    ...initialState,
    userInfo: {},
  },
  getters: {
    user: state => state.user,
    userInfo: state => state.userInfo,
    loggedIn: state => state.loggedIn,
  },
  mutations: {
    setPermissions(state, permissions) {
      const user = { ...state.user, permissions }
      localStorage.setItem("userData", JSON.stringify(user));
      state.user = user
    },
    setRequireChangePassword(state, data) {
      const user = { ...state.user, requireChangePassword: data }
      localStorage.setItem("userData", JSON.stringify(user));
      state.user = user
    },
    loginSuccess(state, user) {
      state.loggedIn = true;
      state.user = user;
    },
    logout(state) {
      state.loggedIn = false;
      state.user = null;
      state.userInfo = {};
    },
    userInfo(state, data) {
      state.userInfo = data;
    },
  },
  actions: {
    setUser({ commit }, userData) {
      commit("loginSuccess", userData)
    },
    logout({ commit }) {
      commit('logout')

      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem(useJwt.jwtConfig.storageUserKeyName)

      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      // this.$router.push({ name: 'login' })
    },
    async getUserInfo({ commit }) {
      try {
        const response = await axios.get('/agent/info')
        const { data } = response.data

        if (data) {
          const group = data?.group ?? {}
          const permissions = data?.permissions ?? []
          const requireChangePassword = data?.requireChangePassword
          commit('userInfo', group)
          commit('setPermissions', permissions)
          ability.update(permissions);
          if (requireChangePassword) {
            commit('setRequireChangePassword', true)
          }
          return true
        }
        return false
      } catch (error) {
        return false
      }
    },
  },
};
