import { get } from 'lodash'

export default [
  // old v1 agent
  {
    path: '/reports/member_winlose_simple/:groupId?',
    name: 'reports-member-winlose-simple',
    component: () => import('@/views/reports/MemberWinloseSimple.vue'),
    meta: {
      contentClass: 'reports-member-winlose-application',
      pageTitle: 'W/L Member (Simple)',
      breadcrumb: [
        {
          text: 'W/L Member (Simple)',
        },
      ],
      action: 'read',
      resource: 'Reports',
    },
  },
  {
    path: '/reports/member_winlose_detail/:groupId?',
    name: 'reports-member-winlose-detail',
    component: () => import('@/views/reports/MemberWinloseDetail.vue'),
    meta: {
      contentClass: 'reports-member-winlose-application',
      pageTitle: 'W/L Member (Detail)',
      breadcrumb: [
        {
          text: 'W/L Member (Detail)',
        },
      ],
      action: 'read',
      resource: 'Reports',
    },
  },

  // agent
  {
    path: '/reports/agent_winlose_simple/:groupId?',
    name: 'reports-agent-winlose-simple',
    component: () => import('@/views/reports/AgentWinloseSimple.vue'),
    meta: {
      contentClass: 'reports-agent-winlose-application',
      pageTitle: 'W/L Agent (Simple)',
      breadcrumb: [
        {
          text: 'W/L Agent (Simple)',
        },
      ],
      action: 'read',
      resource: 'Reports',
    },
  },
  {
    path: '/reports/agent_winlose_detail/:groupId?',
    name: 'reports-agent-winlose-detail',
    component: () => import('@/views/reports/AgentWinloseDetail.vue'),
    meta: {
      contentClass: 'reports-agent-winlose-application',
      pageTitle: 'W/L Agent (Detail)',
      breadcrumb: [
        {
          text: 'W/L Agent (Detail)',
        },
      ],
      action: 'read',
      resource: 'Reports',
    },
  },

  // test agent
  {
    path: '/test/reports/agent_winlose_simple/:groupId?',
    name: 'test-reports-agent-winlose-simple',
    component: () => import('@/views/reports/AgentWinloseSimple.vue'),
    meta: {
      contentClass: 'reports-agent-winlose-application',
      pageTitle: 'W/L Agent (Simple)',
      breadcrumb: [
        {
          text: 'W/L Agent (Simple)',
        },
      ],
      action: 'read',
      resource: 'Reports',
    },
  },
  {
    path: '/test/reports/agent_winlose_detail/:groupId?',
    name: 'test-reports-agent-winlose-detail',
    component: () => import('@/views/reports/AgentWinloseDetail.vue'),
    meta: {
      contentClass: 'reports-agent-winlose-application',
      pageTitle: 'W/L Agent (Detail)',
      breadcrumb: [
        {
          text: 'W/L Agent (Detail)',
        },
      ],
      action: 'read',
      resource: 'Reports',
    },
  },
  // end test agent
  // end agent

  // old member game lists + detail
  {
    path: '/reports/member_winlose_lists/:groupId?',
    name: 'reports-member-winlose-lists',
    component: () => import('@/views/reports/MemberWinloseLists.vue'),
    meta: {
      contentClass: 'reports-member-winlose-application',
      pageTitle: 'W/L Member (Lists)',
      breadcrumb: [
        {
          text: 'W/L Member (Lists)',
        },
      ],
      action: 'read',
      resource: 'Reports',

      navActiveLink: 'reports-member-winlose-detail',
    },
    beforeEnter(to, from, next) {
      switch (from.name) {
        case 'reports-member-winlose-detail':
          to.meta.navActiveLink = 'reports-member-winlose-detail'
          break;
        case 'reports-member-winlose-simple':
          to.meta.navActiveLink = 'reports-member-winlose-simple'
          break;
        default:
          break
      }

      if (!from.name) {
        // refresh
        const isSimple = get(to, 'query.isSimple')
        if ((String(isSimple).toUpperCase() === "TRUE")) {
          to.meta.navActiveLink = 'reports-member-winlose-simple'
        } else {
          to.meta.navActiveLink = 'reports-member-winlose-detail'
        }
      }

      next()
    },
  },

  // new player game lists + detail
  {
    path: '/reports/player_winlose_lists/:groupId?',
    name: 'reports-player-winlose-lists',
    component: () => import('@/views/reports/PlayerWinloseLists.vue'),
    meta: {
      contentClass: 'reports-player-winlose-application',
      pageTitle: 'W/L Player (Lists)',
      breadcrumb: [
        {
          text: 'W/L Player (Lists)',
        },
      ],
      action: 'read',
      resource: 'Reports',

      navActiveLink: 'reports-player-winlose-detail',
    },
    beforeEnter(to, from, next) {
      switch (from.name) {
        case 'reports-player-winlose-detail':
          to.meta.navActiveLink = 'reports-player-winlose-detail'
          break;
        case 'reports-player-winlose-simple':
          to.meta.navActiveLink = 'reports-player-winlose-simple'
          break;
        default:
          break
      }

      if (!from.name) {
        // refresh
        const isSimple = get(to, 'query.isSimple')
        if ((String(isSimple).toUpperCase() === "TRUE")) {
          to.meta.navActiveLink = 'reports-player-winlose-simple'
        } else {
          to.meta.navActiveLink = 'reports-player-winlose-detail'
        }
      }

      next()
    },
  },

  // player
  {
    path: '/reports/player_winlose_simple/:groupId?',
    name: 'reports-player-winlose-simple',
    component: () => import('@/views/reports/PlayerWinloseSimple.vue'),
    meta: {
      contentClass: 'reports-player-winlose-application',
      pageTitle: 'W/L Player (Simple)',
      breadcrumb: [
        {
          text: 'W/L Player (Simple)',
        },
      ],
      action: 'read',
      resource: 'Reports',
    },
  },
  {
    path: '/reports/player_winlose_detail/:groupId?',
    name: 'reports-player-winlose-detail',
    component: () => import('@/views/reports/PlayerWinloseDetail.vue'),
    meta: {
      contentClass: 'reports-player-winlose-application',
      pageTitle: 'W/L Player (Detail)',
      breadcrumb: [
        {
          text: 'W/L Player (Detail)',
        },
      ],
      action: 'read',
      resource: 'Reports',
    },
  },

  // match

  {
    path: '/reports/match_winlose_simple',
    name: 'reports-match-winlose-simple',
    component: () => import('@/views/reports/MatchWinloseSimple.vue'),
    meta: {
      contentClass: 'reports-match-winlose-application',
      pageTitle: 'W/L Match (Simple)',
      breadcrumb: [
        {
          text: 'W/L Match (Simple)',
        },
      ],
      action: 'read',
      resource: 'Reports',
    },
  },
  {
    path: '/reports/match_winlose_detail',
    name: 'reports-match-winlose-detail',
    component: () => import('@/views/reports/MatchWinloseDetail.vue'),
    meta: {
      contentClass: 'reports-match-winlose-application',
      pageTitle: 'W/L Match (Detail)',
      breadcrumb: [
        {
          text: 'W/L Match (Detail)',
        },
      ],
      action: 'read',
      resource: 'Reports',
    },
  },

  // agents outstanding
  {
    path: '/reports/agents_outstanding/:groupId?',
    name: 'reports-agents-outstanding',
    component: () => import('@/views/reports/AgentsOutstanding.vue'),
    meta: {
      contentClass: 'reports-agents-outstanding-application',
      pageTitle: 'Agents Outstanding',
      breadcrumb: [
        {
          text: 'Agents Outstanding',
        },
      ],
      action: 'read',
      resource: 'Reports',
    },
  },

  // players outstanding
  {
    path: '/reports/players_outstanding/:groupId?',
    name: 'reports-players-outstanding',
    component: () => import('@/views/reports/PlayersOutstanding.vue'),
    meta: {
      contentClass: 'reports-players-outstanding-application',
      pageTitle: 'Players Outstanding',
      breadcrumb: [
        {
          text: 'Players Outstanding',
        },
      ],
      action: 'read',
      resource: 'Reports',
    },
  },

  {
    path: '/reports/player_outstanding/lists/:groupId?',
    name: 'reports-player-outstanding-lists',
    component: () => import('@/views/reports/PlayerOutstandingLists.vue'),
    meta: {
      contentClass: 'reports-player-outstanding-lists-application',
      pageTitle: 'Player Outstanding',
      breadcrumb: [
        {
          text: 'Player Outstanding',
        },
      ],
      action: 'read',
      resource: 'Reports',

      navActiveLink: 'reports-player-outstanding',
    },
    beforeEnter(to, from, next) {
      to.meta.navActiveLink = 'reports-player-outstanding'
      next()
    },
  },

  {
    path: '/reports/match_winlose_lists/',
    name: 'reports-match-winlose-lists',
    component: () => import('@/views/reports/MatchWinloseLists.vue'),
    meta: {
      contentClass: 'reports-match-winlose-application',
      pageTitle: 'W/L Match (Lists)',
      breadcrumb: [
        {
          text: 'W/L Match (Lists)',
        },
      ],
      action: 'read',
      resource: 'Reports',
    },
    beforeEnter(to, from, next) {
      switch (from.name) {
        case 'reports-match-winlose-detail':
          to.meta.navActiveLink = 'reports-match-winlose-detail'
          break;
        case 'reports-match-winlose-simple':
          to.meta.navActiveLink = 'reports-match-winlose-simple'
          break;
        default:
          break
      }

      if (!from.name) {
        // refresh
        const isSimple = get(to, 'query.isSimple', false)
        if ((String(isSimple).toUpperCase() === "TRUE")) {
          to.meta.navActiveLink = 'reports-match-winlose-simple'
        } else {
          to.meta.navActiveLink = 'reports-match-winlose-detail'
        }
      }

      next()
    },
  },
]
