export default [
  // billing
  {
    path: '/billing/my-billing',
    name: 'my-billing',
    component: () => import('@/views/billing/MyBilling.vue'),
    meta: {
      pageTitle: 'Billing',
      action: 'read',
      resource: 'Billing',
    },
  },
  {
    path: '/billing/downline-billing',
    name: 'downline-billing',
    component: () => import('@/views/billing/DownlineBilling.vue'),
    meta: {
      pageTitle: 'Downline Billing',
      action: 'read',
      resource: 'Billing',
    },
  },

]
